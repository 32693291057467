export class Environment {
	// Setea una variable que nos dice si estamos el entorno de producción o en el de integración
    setEnvironmentVar(fileName) {
        let envs = ["c", "i", "d"];

        if (this.getUrlVar("wm_env") != "" || sessionStorage.wm_env != "") {
            wm_env = this.getUrlVar("wm_env") || sessionStorage.wm_env;
        }

        if (envs.indexOf(wm_env) === -1) {
            wm_env = "c";
        } else {
            sessionStorage.wm_env = wm_env;
        }

        if (wm_env !== 'c') console.log('Entorno de ' + fileName + ': ' + wm_env);
    }

    // Comprueba la entrada del usuario y setea el modo debug acorde a ésta
    setDebugMode(param) {
        let userInput = this.getUrlVar(param);
        if (userInput === "") return; // Sólo seteamos si tenemos entrada por parte del usuario
        
        if (userInput !== "on") { // desactivando
            sessionStorage.removeItem(param);
        } else { // activado
            sessionStorage.setItem(param, userInput);
        }
    }

    // Devolverá true si estamos en modo debug
    getDebugMode(param) {
        return sessionStorage.getItem(param) === "on";
    }

    // Recupera un parámetro de la url
    getUrlVar(param) {
        let href = window.location.search;
        let i, pair;
        let query = href.split("?")[1];
        let vars = query ? query.split("&") : [];

        for (let i = 0; i < vars.length; i++) {
            pair = vars[i].split("=");
            if (pair[0] == param && pair[1] !== "") return pair[1];
        }

        return "";
    }

    shouldNotLoad(){
        var param = this.getUrlVar('wm_script')
        return (param === 'disable');
    }
}