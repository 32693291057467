export class Config {
	constructor(config) {
		this.config = config;
	}

	// Devuelve un param de la config
    get(config_name) {
        // Comprobar existencia del parámetro
        if (this.config[config_name]) {
             return this.config[config_name];
        } else {
            console.error('El parámetro ' + config_name + ' no está definido en la configuración');
            return false;
        }
    }
}