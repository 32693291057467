import { Config } from '../../common/src/config.js';
import { Environment } from '../../common/src/environment.js';
import { ScriptManagement } from '../../common/src/scriptManagement.js';

(function(factory) {
    // Initialization code: config and file upload
    var c = new Config({
        testFileName: 'dal',
        testUrlParam: 'debugDAL',
        testIframeUrl: '//ws.walmeric.com/static/dft/test/v1/dal.js'
    });
    var e = new Environment();
    var sm = new ScriptManagement();
    
    // We check if we want to replace this script with the one from /test/
    e.setDebugMode(c.get('testUrlParam'));
    if (e.getDebugMode(c.get('testUrlParam')) && !sm.checkTestScript(c.get('testFileName'))) {
        sm.loadScriptDelayingCallback(c.get('testIframeUrl'), c.get('testFileName'));
        return;
    }

    // Ejecutar DAL
    window.DELIO = window.DELIO || {}; // DELIO Global Object
    if (!window.DAL)
        window.DELIO.Async = window.DAL = factory();

}(function() {

    var DAL = {};

    DAL.Ajax = function(options) {
        return new Ajax(options);
    };

    DAL.Promise = Promise;

    function Ajax (options) {
        this.options = options;

        var _this = this,
            o = this.options,
            reqType = o.type.toLowerCase();

        if (!o.type)
            throw 'Param "type" is required';

        if (!o.url)
            throw 'Param "url" is required';

        this.def = new Promise();

        if(reqType =="jsonp" && o.url.indexOf('wm__control=checked') !== -1) {
            // The substring 'wm__control=checked' is present in the string
            console.log("JSONP TO POST");
            reqType ="post";
        }

        if (reqType === "get" || reqType === "json" || reqType === "jsonp")
            o.url = composeUrl(o);

        switch (reqType) {
            case "get":
            case "json":
                _this.get();
                break;
            case "jsonp":
                _this.jsonp();
                break;
            case "post":
                _this.post();
                break;
            case "script":
                _this.script();
                break;
            case "style":
                _this.style();
                break;
            default:
                throw 'Param "type" only accepts the values: get, post, json, jsonp, script, style';
        }

        return this.def;
    };

    Ajax.prototype.get = function() {
        var _this = this,
            o = this.options;

        var request = new XMLHttpRequest();
        request.open('GET', o.url, true);

        request.onreadystatechange = function() {
            if (this.readyState === 4) {
                if (this.status >= 200 && this.status < 400) {
                    // Success!
                    var resp = this.responseText;
                    if (o.type === 'json')
                        resp = JSON.parse(resp);
                    _this.def.resolve(resp);
                } else {
                    _this.def.reject({ type: "error", code: this.status, description: this.statusText });
                }
            }
        };

        request.onerror = function (error) {
            _this.def.reject(error);
        };

        request.send();
        request = null;
    };


    var uuid = 0;
    Ajax.prototype.jsonp = function() {
        var _this = this,
            o = this.options;
    
        // ... (Check if wm__control=checked is present) ...
    
        var head = document.head || document.getElementsByTagName('head')[0],
            main_script, name;
    
        // CREATE SCRIPT
        let e = document.createElement('script');
        e.type = 'application/javascript';
    
        let env = new Environment();
        if (env.shouldNotLoad()) {
            e.dataType = "jsonp"; //blocks execution
        }
    
        // INIT
        name = o.jsonpCallback || '__jsonp_' + uuid;
        uuid++;
    
        if (o.url.match(/\?/))
            o.url += '&callback=' + name;
        else
            o.url += '?callback=' + name;
    
        // Set src attribute
        e.src = o.url;
    
        // Error Reject
        e.onerror = function (error) {
            _this.def.reject(error);
        };
    
        // Setup handler
        window[name] = function(data) {
            _this.def.resolve(data);
    
            if (e) {
                if(e.parentNode){
                    head.removeChild(e);
                }else{
                    // Handle the case where the script doesn't have a parentNode
                    if(typeof statusDFI !="undefined" && typeof statusDFI.debuggerDAL != "undefined"){
                        console.log('NO PARENT');
                        console.log(e);
                        console.log(e.parentNode)
                    }
                }
                e = null; 
            }
            try {
                delete window[name];
            } catch (e) {
                window[name] = undefined;
            }
        };
    
        // Load JSON
        if (uuid <= 2000) {
            try {
                head.appendChild(e); 
            } catch (err) {
                if(typeof statusDFI !="undefined" && typeof statusDFI.debuggerDAL != "undefined"){
                    console.log('Err:Catch:DAL')
                }
            }
        } else{
            console.log('uid:limit_reached');
        }
    };
    

    Ajax.prototype.post = function() {
        var _this = this,
            o = this.options;

        //Params:
        let url = o.url.split('?')[0];
        let params = JSON.parse('{"' + decodeURI(o.url.split('?')[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')

        //  Externa, origen de lead
        if( typeof params['ete_998'] == 'undefined' ) {
            if (params.phone !== '' && typeof params.phone !== 'undefined'  && params.Email !== '' && typeof params.Email !== 'undefined') {
                params['ete_998'] = 3;
            } else if (params.phone !== '') {
                params['ete_998'] = 2;
            } 
        }


        //  Callback
        let callback = '__jsonp_' + uuid;
        uuid++;
        url += '?idTag=' + params.idTag + '&format=' + params.format;

        let queryString = Object.keys(params).map(function(key) {
            return decodeURIComponent(key) + '=' +
                decodeURIComponent(params[key]);
        }).join('&');

        //Request
        var request = new XMLHttpRequest();
        request.open('POST', url, true);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        request.send(queryString);

        request.onreadystatechange = function() {
            if (this.readyState === 4) {
                if (this.status >= 200 && this.status < 400) {
                    var resp = this.responseText;
                    _this.def.resolve(JSON.parse(resp));
                } else {
                    _this.def.reject({ type: "error", code: this.status, description: this.statusText });
                }
            }
        };
    };

    Ajax.prototype.script = function() {
        var _this = this,
            o = this.options;

        var script = document.createElement('script');
        script.src = o.url;
        script.async = true;
        script.type = "application/javascript";

        let e = new Environment();
        if (e.shouldNotLoad()) {
            script.dataType = "jsonp"; //blocks execution
        }

        addEventListener(script, 'load', function() {
            _this.def.resolve();
        });
        (document.body) ? document.body.appendChild(script) : document.head.appendChild(script) ;
    };


    /*
    |---------------------------------------------------------------------------
    | Load a style sheet asynchronously.
    |---------------------------------------------------------------------------
    */
    Ajax.prototype.style = function() {

        var _this = this;

        var head = document.head || document.getElementsByTagName('head')[0];
        var style = document.createElement('link');
        style.rel = "stylesheet";
        style.type = "text/css";
        style.href = this.options.url;

        addEventListener(style, 'load', function() {
            _this.def.resolve();
        });

        head.appendChild(style);
    };


    /**
     * Compose URL with GET params
     */
    function composeUrl(options) {
        var o = options,
            url;

        if (!o.data)
            return o.url;

        var paramName, params = "";
        for (paramName in o.data) {
            params += paramName + "=" + o.data[paramName] + "&";
        }
        params = params.substr(0, params.length - 1);

        var bindingChar = o.url.match(/\?/g) ? "&" : "?";

        url = o.url + bindingChar + params;

        return url;
    }

    /**
     * addEventListener SUPPORT FOR IE8+
     */
    function addEventListener(el, eventName, fn) {
        if (el.addEventListener) {
            el.onload = fn;
            //el.addEventListener(eventName, fn); // it had problems capturing the event
        } else {
            if (eventName === "load") {
                el.onreadystatechange = function() {
                    if (el.readyState === "loaded" ||
                        el.readyState === "complete")
                        fn.call(el);
                };
            } else {
                el.attachEvent('on' + eventName, function() {
                    fn.call(el);
                });
            }
        }
    }



    /**
     * "Promises" supported as [native](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise)
     * interface and [$.Deferred](http://api.jquery.com/category/deferred-object/).
     *
     * @class   Promise
     * @param   {Function}  [executor]
     * @returns {Promise}
     */
    function Promise (executor) {
        var _completed = false;

        function _setState(state) {
            return function() {
                if (_completed) {
                    return dfd;
                }

                _args = arguments;
                _completed = true;

                // Overwriting the methods
                dfd.done =
                    dfd.fail =
                    dfd.resolve =
                    dfd.reject = function() {
                        return dfd;
                    };

                dfd[state ? 'done' : 'fail'] = function(fn) {
                    /* istanbul ignore else */
                    if (typeof fn === 'function') {
                        fn.apply(dfd, _args);
                    }
                    return dfd;
                };

                var fn,
                    fns = state ? _doneFn : _failFn,
                    i = 0,
                    n = fns.length;

                for (; i < n; i++) {
                    fn = fns[i];
                    /* istanbul ignore else */
                    if (typeof fn === 'function') {
                        fn.apply(dfd, _args);
                    }
                }

                fns = _doneFn = _failFn = null;

                return dfd;
            };
        }

        var
            _args,
            _doneFn = [],
            _failFn = [],

            dfd = /** @lends Promise.prototype */ {

                /**
                 *Adds a handler that will be called when the "promise" is "resolved"
                 * Añade un manejador que se llama cuando la "promesa" se "permitió"
                 * @param  {Function}  fn  handler function
                 * @returns {Promise}
                 */
                done: function(fn) {
                    _doneFn.push(fn);
                    return dfd;
                },

                /**
                 * Adds a handler that will be called when the "promise" is "cancelled"
                 * @param  {Function}  fn  handler function
                 * @returns {Promise}
                 */
                fail: function(fn) {
                    _failFn.push(fn);
                    return dfd;
                },

                /**
                 * Добавляет сразу два обработчика
                 * Añade sólo dos manejador
                 * @param   {Function}   [doneFn]   will be fulfilled when the "promise" is "resolved"
                 * @param   {Function}   [failFn]   or when the "promise" is "revoked"
                 * @returns {Promise}
                 */
                then: function(doneFn, failFn) {
                    var promise = Promise();

                    dfd
                        .done(_then(promise, 'resolve', doneFn))
                        .fail(_then(promise, 'reject', failFn));

                    return promise;
                },

                notify: noop, // jQuery support
                progress: noop, // jQuery support
                promise: function() {
                    // jQuery support
                    return dfd;
                },


                /**
                 * Add a "promise" handler regardless of fulfillment
                 * @param   {Function}   fn   handler function
                 * @returns {Promise}
                 */
                always: function(fn) {
                    return dfd.done(fn).fail(fn);
                },

                getState: function() {
                    return _completed;
                },


                /**
                 * "Resolve" "promise"
                 * @method
                 * @param    {*}  result
                 * @returns  {Promise}
                 */
                resolve: _setState(true),


                /**
                 * "Cancel" "promise"
                 * @method
                 * @param   {*}  result
                 * @returns {Promise}
                 */
                reject: _setState(false)
            };


        /**
         * @name  Promise#catch
         * @alias fail
         * @method
         */
        dfd['catch'] = function(fn) {
            return dfd.then(null, fn);
        };


        // Let's work how native Promises 
        /* istanbul ignore else */
        if (typeof executor === 'function') {
            try {
                executor(dfd.resolve, dfd.reject);
            } catch (err) {
                dfd.reject(err);
            }
        }


        function noop() {}


        function _then(promise, method, callback) {
            return function() {
                var args = arguments,
                    retVal;

                /* istanbul ignore else */
                if (typeof callback === 'function') {
                    try {
                        retVal = callback.apply(promise, args);
                    } catch (err) {
                        promise.reject(err);
                        return;
                    }

                    if (retVal && typeof retVal.then === 'function') {
                        if (retVal.done && retVal.fail) {
                            retVal.done(promise.resolve).fail(promise.reject);
                        } else {
                            retVal.then(promise.resolve, promise.reject);
                        }
                        return;
                    } else {
                        args = [retVal];
                        method = 'resolve';
                    }
                }

                promise[method].apply(promise, args);
            };
        }


        return dfd;
    };


/**
 * Returns a Promise that resolves with an array of the resolved values of the input iterable.
 *
 * @param {Iterable<Promise>} iterable - An iterable of promises.
 * @return {Promise<Array>} A Promise that resolves with an array of the resolved values of the input iterable.
 */
    Promise.all = function(iterable) {
        var dfd = Promise(),
            d,
            i = 0,
            n = iterable.length,
            remain = n,
            values = [],
            _fn,
            _doneFn = function(i, val) {
                (i >= 0) && (values[i] = val);

                /* istanbul ignore else */
                if (--remain <= 0) {
                    dfd.resolve(values);
                }
            },
            _failFn = function(err) {
                dfd.reject([err]);
            };

        if (remain === 0) {
            _doneFn();
        } else {
            for (; i < n; i++) {
                d = iterable[i];

                if (d && typeof d.then === 'function') {
                    _fn = _doneFn.bind(null, i); // todo: тест
                    if (d.done && d.fail) {
                        d.done(_fn).fail(_failFn);
                    } else {
                        d.then(_fn, _failFn);
                    }
                } else {
                    _doneFn(i, d);
                }
            }
        }

        return dfd;
    };


    /**
     * Returns a Promise that resolves or rejects as soon as one of the promises in the iterable resolves or rejects, with the value or reason from that promise.
     *
     * @param {Iterable<Promise>} iterable - An iterable object of promises.
     * @return {Promise} A new promise that is settled the same way as the first promise to settle in the iterable.
     */
    Promise.race = function(iterable) {
        return Promise.all(iterable).then(function(values) {
            return values.pop();
        });
    };

/**
 * Casts a value to a Promise.
 *
 * @param {*} value - The value to be cast.
 * @return {Promise} - The casted Promise.
 */
    Promise.cast = function(value) {
        var promise = Promise().resolve(value);
        return value && typeof value.then === 'function' ? promise.then(function() {
            return value;
        }) : promise;
    };


    /**
     * Returns a resolved promise with the given value.
     *
     * @static
     * @memberOf Promise
     * @param {*} value - The value to resolve the promise with.
     * @returns {Promise} A resolved promise.
     */
    Promise.resolve = function(value) {
        return Promise().resolve(value);
    };


    /**
     * Returns a rejected promise with the given value.
     *
     * @static
     * @memberOf Promise
     * @param {*} value - The value to reject the promise with.
     * @returns {Promise} A rejected promise.
     */
    Promise.reject = function(value) {
        return Promise().reject(value);
    };


    /**
     * Map over the values in the provided map object asynchronously.
     *
     * @param {Object} map - The object containing key-value pairs to map over
     * @return {Promise} A promise that resolves to an object with mapped values
     */
    Promise.map = function(map) {
        var array = [],
            key, idx = 0,
            results = {};

        for (key in map) {
            array.push(map[key]);
        }

        return Promise.all(array).then(function(values) {
            /* jshint -W088 */
            for (key in map) {
                results[key] = values[idx++];
            }

            return results;
        });
    };

    return DAL;

}));
