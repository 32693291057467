export class ScriptManagement {
	// Comprueba si está cargado el script de test
    checkTestScript(fileName) {
        let loadedScripts = document.querySelectorAll('script[src*="' + fileName + '.js"]');
        for (let i=0, script; i<loadedScripts.length; i++) {
            script = loadedScripts[i];
            if (script.src.indexOf('/test/') !== -1) {
                console.log('Cargado script de test de ' + fileName.toUpperCase());
                return true;
            }
        }

        return false;
    }

    // Carga asíncrona de otro script
    loadScript (url, urlParams){
        let script = document.createElement('script');
        script.src = this.composeUrl(url, urlParams);
        script.async = true;
        script.type = "application/javascript";
        document.body.appendChild(script);
    }

    // Carga otro script diferente al actual, conservando la llamada al callback de este y postponiendo su ejecución a la carga del otro script
    loadScriptDelayingCallback (url, fileName, urlParams){
        document['scriptLoad' + fileName.toUpperCase()] = document.querySelector('script[src*="' + fileName + '.js"]').onload;
        document.querySelector('script[src*="' + fileName + '.js"]').onload = () => {
            let script = document.createElement('script');
            script.src = this.composeUrl(url, urlParams, fileName);
            script.async = true;
            script.type = "application/javascript";
            // Callback según navegador
            if (script.readyState){ // IE
                script.onreadystatechange = function(){
                    if (script.readyState == "loaded" || script.readyState == "complete"){
                        script.onreadystatechange = null;
                        if (document['scriptLoad' + fileName.toUpperCase()]) document['scriptLoad' + fileName.toUpperCase()]();
                    }
                }
            } else { // Otros
                script.onload = function(){
                    if (document['scriptLoad' + fileName.toUpperCase()]) document['scriptLoad' + fileName.toUpperCase()]();
                }
            }
            document.body.appendChild(script);
        };  
    }

    // Función privada para componer los parámetros adicionales de la url
    composeUrl (url, urlParams, fileName){
        urlParams = urlParams || [];
        for (let i=0, param, concatChar; i<urlParams.length; i++) {
            param = urlParams[i];
            concatChar = (i == 0) ? '?' : '&';
            paramValue = window[param.value] || this.getParamFromScriptUrl(param.key, fileName);
            url += concatChar + param.key + "=" + paramValue;
        }

        return url;
    }

    // Recupera un parámetro de la url del script
    getParamFromScriptUrl (param, fileName){
        let script = document.querySelector('script[src*="' + fileName + '.js"]');
        let i, pair;
        let query = script.src.split("?")[1];
        let vars = query ? query.split("&") : [];

        for (let i = 0; i < vars.length; i++) {
            pair = vars[i].split("=");
            if (pair[0] == param && pair[1] !== "") return pair[1];
        }

        return "";
    }
}